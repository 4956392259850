<template>
  <div class="overall-page">
    <div class="head">
      <div class="head-left">
        <img src="@/assets/img/wang/back.png" @click="back" alt="" />
        <span>详情</span>
      </div>
    </div>
    <div class="page">
      <Breadcrumb :routerList="routerList"></Breadcrumb>
      <div class="info-box">
        <div class="head-card">
          <div class="head-card-left">
            <img class="shadow" src="@/assets/img/wang/shadow.png" />
          </div>
          <div class="head-card-right">
            <div class="title-box">
              <div class="title-box-left" v-if="myInfo.warningOrgIds">
                <div>已预警</div>
              </div>
              <div class="title-box-right" v-html="myInfo.title"></div>
            </div>
            <div class="early-warning">
              <div class="platform-type">{{ platformTypeName }}</div>
              <div class="time">发布时间：{{ myInfo.releaseTime }}</div>
              <div
                class="copy"
                @click="$main.lookYuanWen(myInfo.webSite)"
                style="margin-left: 0.9375rem;font-size: 0.875rem;cursor: pointer"
              >
                <img src="@/assets/img/wang/article.png" />
                <span style="color: #2e59ec;position: relative;top: -0.125rem;">
                  查看原文
                </span>
              </div>
            </div>
            <div class="operation">
              <div class="copy">
                <span>平台类型：{{ platformTypeName || "无" }}</span>
                <img
                  class="m-l"
                  @click="$main.copyFun(platformTypeName)"
                  src="@/assets/img/wang/copy-all.png"
                />
              </div>
              <div class="copy">
                <span>账号名称/网站名称：{{ myInfo.accountName || "无" }}</span>
                <img
                  class="m-l"
                  @click="$main.copyFun(myInfo.accountName)"
                  src="@/assets/img/wang/copy-all.png"
                />
              </div>
              <div class="copy">
                <span>关键字：{{ myInfo.keyWord || "无" }}</span>
                <img
                  class="m-l"
                  @click="$main.copyFun(myInfo.keyWord)"
                  src="@/assets/img/wang/copy-all.png"
                />
              </div>
            </div>
          </div>
          <img class="shadow2" src="@/assets/img/wang/shadow2.png" />
        </div>
        <div class="content" v-html="myInfo.content"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/breadcrumb.vue";
import { info } from "@/api/monitor/index";
export default {
  components: { Breadcrumb },
  data() {
    return {
      routerList: ["系统设置", "详情"],
      myInfo: {}, // 详情信息
      id: null,
      platformTypeName: "",
    };
  },
  created() {
    let params = JSON.parse(decodeURIComponent(this.$route.query.info));
    if (params) {
      this.id = params.id;
      this.platformTypeName = params.platformTypeName;
    }
    this.getdetail();
  },
  mounted() {},
  methods: {
    async getdetail() {
      const { id } = this;
      const Res = await info(id);
      const { code, data } = Res.data;
      if (code == 200) {
        this.myInfo = data;
      }
    },
    back() {
      this.$router.push("/settings");
    },
  },
};
</script>

<style lang="scss" scoped>
.overall-page {
  width: 100%;
  .head {
    display: flex;
    width: 100%;
    height: 56px;
    background: #ffffff;
    position: fixed;
    margin-top: 0;
    z-index: 100;
    .head-left {
      display: flex;
      height: 100%;
      width: 50%;
      align-items: center;
      img {
        height: 38px;
        width: 38px;
        margin-left: 23px;
        margin-right: 15px;
        cursor: pointer;
      }
      span {
        height: 28px;
        font-size: 20px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
      }
    }
  }
  .page {
    padding-top: 72px;
    .info-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 40px;
      background: #ffffff;
      border-radius: 8px;
      margin-top: 16px;
      .head-card {
        display: flex;
        align-items: center;
        width: 100%;
        border: 1px solid #e4e6ec;
        .head-card-left {
          display: flex;
          align-items: flex-end;
          justify-content: flex-start;
          height: auto;
          width: 200px;
          .shadow {
            height: 74px;
            width: 57px;
          }
        }
        .head-card-right {
          display: flex;
          flex-direction: column;
          width: calc(100% - 370px);
          margin: 40px 0px 32px 0px;
        }
        .title-box {
          display: flex;
          width: 100%;
          min-height: 42px;
          .title-box-left {
            display: flex;
            align-items: flex-start;
            height: 100%;
            width: 58px;
            margin-right: 16px;
            div {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 58px;
              height: 24px;
              background: #ffedef;
              font-size: 14px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #ea3342;
              white-space: nowrap;
              margin-top: 10px;
            }
          }
          .title-box-right {
            width: calc(100% - 60px);
            min-height: 42px;
            line-height: 42px;
            font-size: 30px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
          }
        }
        .early-warning {
          display: flex;
          align-items: center;
          width: 100%;
          height: auto;
          margin: 16px 0px;
          .header-img {
            height: 50px;
            width: 52px;
            border-radius: 50%;
            margin-right: 24px;
          }
          .warning-type {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 5px;
            height: 24px;
            width: auto;
            background: #ff4b1f;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            margin-right: 16px;
          }
          .platform-type {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 5px;
            height: 24px;
            width: auto;
            background: #f58030;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            margin-right: 16px;
          }
          .read {
            width: 17px;
            height: 15px;
            margin-right: 8px;
          }
          .text {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #02bc7c;
            margin-right: 16px;
          }
          .time {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
          }
        }
        .operation {
          display: flex;
          height: 20px;
          width: auto;
          .copy {
            display: flex;
            align-items: center;
            height: 20px;
            width: auto;
            cursor: pointer;
            margin-right: 41px;
            img {
              width: 16px;
              height: 16px;
              margin-right: 8px;
            }
            span {
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
            }
          }
        }
        .shadow2 {
          height: 100%;
          width: 170px;
        }
        .disposed {
          height: 114px;
          width: 129px;
          position: absolute;
          margin-left: calc(100% - 386px);
        }
      }
      .platform {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 1164px;
        height: auto;
        background: #f4f5fa;
        padding: 24px 24px 0px 32px;
        margin: 24px 0px 24px 0px;
        .img {
          height: 110px;
          width: 110px;
          position: absolute;
          margin-left: 1000px;
          margin-bottom: 24px;
        }
        .platform-list {
          display: flex;
          height: 20px;
          width: 100%;
          margin-bottom: 24px;
          .platform-time {
            display: flex;
            align-items: center;
            height: 20px;
            width: 50%;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            img {
              height: 16px;
              width: 17px;
              margin-left: 10px;
              margin-top: 3px;
              cursor: pointer;
            }
          }
        }
        .platform-name {
          display: flex;
          align-items: center;
          height: 20px;
          width: 100%;
          margin-bottom: 24px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          img {
            height: 16px;
            width: 17px;
            margin-left: 10px;
            margin-top: 3px;
            cursor: pointer;
          }
        }
      }
      .content {
        width: 1164px;
        height: auto;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        margin-top: 20px;
        line-height: 25px;
        text-indent: 25px;
      }
    }
    .be-similar {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: auto;
      padding: 40px 240px 26px 240px;
      background: #ffffff;
      border-radius: 8px;
      margin-top: 16px;
      .be-similar-account {
        display: flex;
        align-items: center;
        height: 28px;
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: bold;
        color: #333333;
        margin-bottom: 10px;
        span:nth-child(1) {
          z-index: 10;
        }
        span:nth-child(2) {
          font-size: 14px;
        }
        .be-similar-color {
          width: 77px;
          height: 6px;
          background: linear-gradient(90deg, #f89d55 0%, #f4640c 100%);
          position: absolute;
          margin-left: 0px;
          margin-top: 22px;
          z-index: 9;
        }
      }
      .data-box {
        display: flex;
        width: 100%;
        height: auto;
        padding: 24px 0px;
        border-bottom: 1px solid #e4e6ec;
        .data-box-left {
          width: 104px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          img {
            height: 56px;
            width: 56px;
            border-radius: 50%;
          }
          .platform-type {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 24px;
            width: auto;
            background: #f58030;
            max-width: 104px;
            padding: 0px 22px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            margin-top: 9px;
          }
        }
        .data-box-right {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 100%;
          height: 100%;
          .name-box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: auto;
            margin-bottom: 16px;
            .name-box-left {
              display: flex;
              align-items: center;
              width: calc(100% - 170px);
              height: auto;
              span {
                display: flex;
                align-items: center;
                justify-content: center;
                width: auto;
                height: 24px;
                padding: 0px 8px;
                background: #ff4b1f;
                font-size: 14px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #ffffff;
                margin-right: 16px;
              }
              .name {
                height: 22px;
                width: calc(100% - 200px);
                line-height: 22px;
                font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
                cursor: pointer;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                line-clamp: 1;
                -webkit-box-orient: vertical;
              }
            }
            .time {
              display: flex;
              align-items: center;
              justify-content: flex-end;
              height: 100%;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
            }
            .bad-num {
              display: flex;
              justify-content: flex-end;
              font-size: 14px;
              width: 20%;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #e60b1e;
            }
          }
          .data-info {
            display: flex;
            height: 20px;
            width: 100%;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            .status {
              color: #02bc7c;
              margin-right: 46px;
            }
            .region {
              max-width: 180px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              margin-right: 46px;
            }
            .subject-type {
              margin-right: 46px;
            }
            .company {
              max-width: 300px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              margin-right: 46px;
            }
            .fnas {
              margin-right: 46px;
            }
          }
        }
      }
    }
  }
}
.yujin {
  background: #ffedef;
  font-size: 14px;
  color: #ea3342;
  font-weight: 500;
  padding: 4px 8px;
}
.bad-num {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #2e59ec;
  margin-left: 18px;
  cursor: pointer;
  img {
    height: 16px;
    width: 18px;
    margin-right: 8px;
  }
}
</style>
